import { ElMessage, ElLoading, ElNotification } from 'element-plus';
import { Options } from 'vue-class-component';
import { SuperVue } from '@/mixins/SuperVue';
import Api from '@/api/index';
import store from '@/store';
import Utils from '@/utils';

// eslint-disable-next-line no-shadow
enum LoginWay {
  PASS,
  SMS
}
@Options({
  components: {}
})
class Login extends SuperVue {
  $refs!: {
    ruleForm: any;
    ruleForm2: any;
    imgRef: any;
  };
  created() {
    this.writePassInPage();
  }

  loginWay = LoginWay.PASS;
  changeLoginWay(way: LoginWay) {
    this.loginWay = way;
  }

  verifyCodeUrl = '/api/v1/image/create';
  loginData = {
    phone: '',
    password: '',
    sms: '',
    verify_code: '',
    save: false
  };
  loginRules = {
    phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
    verify_code: [
      { required: true, message: '验证码不能为空', trigger: 'blur' }
    ],
    password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
  };
  loginRules2 = {
    phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
    sms: [{ required: true, message: '验证码不能为空', trigger: 'blur' }]
  };
  isAgree: boolean = true;
  smsTime = 0;

  // 刷新验证码
  refresh() {
    this.$refs.imgRef.src = this.$url + this.verifyCodeUrl;
  }

  // 开始短信倒计时
  startCountDowm() {
    const second = 60;
    this.smsTime = second;
    const timeStamp = new Date().getTime();
    const timer = setInterval(() => {
      this.smsTime = Math.ceil(
        second - (new Date().getTime() - timeStamp) / 1000
      );
      if (this.smsTime < 0) {
        this.smsTime = 0;
        clearInterval(timer);
      }
    }, 1000);
  }
  toAgreement() {
    const route = this.$router.resolve({ path: '/agreement' })
    window.open(route.href, '_blank')
  }
  getSMS() {
    console.log('getSMS :>> ', 11);
    if (this.smsTime !== 0) return;
    if (this.loginData.phone.length !== 11) return;
    this.startCountDowm();
    Api.http_sendCode({ phone: this.loginData.phone }).then(res => {
      if (res.code === '200') {
        this.$message.success('短信发送成功,请注意查收!');
      } else {
        this.$message.error(res.message);
      }
    });
  }
  login() {
    if (this.loginWay === LoginWay.PASS) {
      this.submitForm();
    }
    if (this.loginWay === LoginWay.SMS) {
      this.submitForm2();
    }
  }

  // 表单数据校验
  submitForm(): void {
    // store.dispatch('LOGIN', {
    //   token: 'user_token:eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOlsiMSIsIjIwMjItMDgtMjMgMTk6MDg6MDciXSwiaWQiOiIxIiwiZXhwIjoxNjYyNDYyNDg3fQ.rRZXenwA2L1WWnWOD7neqG_g4YxCEaBSekIKAgAWbjA',
    //   userInfo: {}
    // });
    // Api.htttp_getAccess({}).then(response => {
    //   store.dispatch('SETORIGINACCESS', response.data);
    //   this.$router.push({ name: 'home' });
    // });
    // return
    this.$refs.ruleForm.validate((valid: boolean) => {
      if (valid) {
        Api.http_userLogin(this.loginData).then((res: any) => {
          if (res.code === '200') {
            const loading = ElLoading.service({
              lock: true,
              text: '登录中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            if (this.loginData.save) {
              this.savePass();
            } else {
              this.unSavePass();
            }
            store.dispatch('LOGIN', {
              token: res.data.token,
              userInfo: res.data.userinfo
            });
            let d = -1;
            if (res.data.userinfo.pupdated_at) {
              d = Math.floor((Date.now() - new Date(res.data.userinfo.pupdated_at).getTime()) / (1000 * 60 * 60 * 24));
              if (d > 90) {
                ElNotification({
                  title: '密码修改提醒',
                  message: '您已超过90天未更换密码，为安全起见，请及时更换密码',
                  type: 'warning',
                  duration: 10000
                })
              }
            }
            Api.htttp_getAccess({}).then(response => {
              loading.close();
              store.dispatch('SETORIGINACCESS', response.data);
              const rolePages = [{ id: 69, name: 'pais' }];
              const index = rolePages.findIndex(item => item.id === response.data[0].id);
              if (index > -1) this.$router.push({ name: rolePages[index].name });
              else this.$router.push({ name: 'home' });
            });
          } else {
            ElMessage.warning(res.message);
            this.refresh();
          }
        });
      } else {
        return false;
      }
    });
  }

  // 表单数据校验
  submitForm2(): void {
    this.$refs.ruleForm2.validate((valid: boolean) => {
      if (valid) {
        Api.http_loginSMS({
          phone: this.loginData.phone,
          sms: this.loginData.sms
        }).then((res: any) => {
          if (res.code === '200') {
            const loading = ElLoading.service({
              lock: true,
              text: '登录中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            store.dispatch('LOGIN', {
              token: res.data.token,
              userInfo: res.data.userinfo
            });
            Api.htttp_getAccess({}).then(response => {
              loading.close();
              store.dispatch('SETORIGINACCESS', response.data);
              this.$router.push({ name: 'home' });
            });
          } else {
            ElMessage.warning(res.message);
            this.refresh();
          }
        });
      } else {
        return false;
      }
    });
  }

  // 链接跳转
  toLink(name: string) {
    this.$router.push({ name: name });
  }
  // 记住密码
  savePass() {
    const value = this.loginData.phone + '__cookie__' + this.loginData.password;
    Utils.setCookie('sign', value, 1000 * 60 * 60 * 24 * 30); // 保存密码30天
  }
  unSavePass() {
    Utils.delCookie('sign');
  }
  writePassInPage() {
    const value = Utils.getCookie('sign');
    this.loginData.save = false;
    if (value !== '') {
      const arr = value.split('__cookie__');
      if (arr.length === 2) {
        this.loginData.phone = arr[0];
        this.loginData.password = arr[1];
        this.loginData.save = true;
      }
    }
  }
}

export default Login;
